import React, {PureComponent} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import "./style.css";

class Footer extends PureComponent {
    render() {
        return (
            <footer>
                <Navbar bg="black" variant="dark">
                    <Container>
                        <Navbar.Text>&copy;Yu@n</Navbar.Text>
                        <Nav>
                            <Nav.Link className="pr-0" href="https://beian.miit.gov.cn/" target="_blank">豫ICP备14013430号</Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
            </footer>
        )
    }
}

export default Footer;
