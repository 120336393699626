import React, {PureComponent} from "react";
import {Container, Navbar, Nav, Form, FormControl, Button, NavDropdown} from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import "./style.css"

class Header extends PureComponent {
    render() {
        return (
            <header>
                <Navbar bg="black" variant="dark" fixed="top">
                    <Container>
                        <Navbar.Brand href="/">
                            <img
                                src={Logo}
                                width="30"
                                height="30"
                                className="d-inline-block align-top rounded"
                                alt="wekeey"
                            />
                            &nbsp;未知探索
                        </Navbar.Brand>
                        <Nav className="mr-auto" as="ul">
                            <Nav.Item as="li">
                                <Nav.Link href="#home">首页</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as={"li"}>
                                <Nav.Link href="#category">分类</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as={"li"}>
                                <Nav.Link href="#tags">标签</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as={"li"}>
                                <Nav.Link href="#files">归档</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as={"li"}>
                                <Nav.Link href="#about">关于</Nav.Link>
                            </Nav.Item>
                            <Nav.Item as={"li"}>
                                <NavDropdown title="其他" id="nav-dropdown">
                                    <NavDropdown.Item eventKey="4.1">留言</NavDropdown.Item>
                                    <NavDropdown.Item eventKey="4.2">友链</NavDropdown.Item>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item eventKey="4.4">音乐</NavDropdown.Item>
                                </NavDropdown>
                            </Nav.Item>
                        </Nav>
                        <Form inline>
                            <FormControl type="text" placeholder="搜点什么~" className="mr-sm-2"/>
                            <Button variant="outline-success">搜索</Button>
                        </Form>
                    </Container>
                </Navbar>
            </header>
        )
    }
}

export default Header;
