import React from "react";
import Home from "./pages/Home";
import Header from "./componets/Header";
import Footer from "./componets/Footer";

function App() {
    return (
        <div>
            <Header/>
            <Home/>
            <Footer/>
        </div>
    );
}

export default App;
